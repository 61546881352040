import { Box, Grid, IconButton, Link, Stack, Typography } from '@mui/material'; // Divider
import { styled, useTheme } from '@mui/material/styles';
import MailIcon from 'assets/images/landing/mail.png';

import { ReactComponent as FbIcon } from 'assets/images/landing/fb.svg';
import { ReactComponent as InIcon } from 'assets/images/landing/in.svg';
import { ReactComponent as InstaIcon } from 'assets/images/landing/insta.svg';
import { ReactComponent as XIcon } from 'assets/images/landing/x.svg';

import Logo from 'ui-component/Logo';
import { useNavigate, useNavigation } from 'react-router-dom';

// =============================|| LANDING - FOOTER SECTION ||============================= //

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: '60px 60px 10px',
  background: theme.palette.secondary.main,

  [theme.breakpoints.down('md')]: {
    padding: '24px',
  },
}));

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 8, // Provide extra padding for better spacing
  ':hover': {
    background: '#FFCF421A', // Subtle hover effect
  },
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  marginRight: 20, // Add space between items
  cursor: 'pointer',
  whiteSpace: 'nowrap', // Prevent word wrapping
  '&:hover': {
    textDecoration: 'underline', // Underline on hover for links
  },
}));

const LinkStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(3, 0),
    gap: theme.spacing(1),
  },
}));

const SocialStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

const CenterResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: '1px solid #1111111A',
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

const EndResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const scrollToSection = (section) => {
    const headerOffset = 64; // adjust this value based on your header height
    const element = document.getElementById(section);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  const handleAboutUsClick = () => {
    navigate('/');
    setTimeout(() => {
      scrollToSection('hero-section');
    }, 100);
  };

  const handleContactUsClick = () => {
    navigate('/');
    setTimeout(() => {
      scrollToSection('contact-us');
    }, 100);
  };

  return (
    <SectionWrapper>
      <Box>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={12} md={4}>
            <EndResponsiveBox>
              <Logo />
            </EndResponsiveBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <LinkStack direction={'row'} justifyContent="center">
              <StyledLink onClick={handleAboutUsClick}>Home</StyledLink>
              <StyledLink onClick={handleAboutUsClick}>About us</StyledLink>
              <StyledLink onClick={handleContactUsClick}>Contact us</StyledLink>
              <StyledLink>Terms</StyledLink>
              <StyledLink>Privacy policy</StyledLink>
            </LinkStack>
          </Grid>

          <Grid item xs={12} md={4}>
            <SocialStack>
              <IconButtonWrapper
                aria-label="instagram"
                component={Link}
                target="_blank"
                href="https://www.instagram.com/prodculator/"
              >
                <InstaIcon />
              </IconButtonWrapper>

              <IconButtonWrapper
                aria-label="facebook"
                component={Link}
                target="_blank"
                href="https://www.facebook.com/prodculator"
              >
                <FbIcon />
              </IconButtonWrapper>

              <IconButtonWrapper
                aria-label="linkedin"
                component={Link}
                target="_blank"
                href="https://www.linkedin.com/company/prodculator/"
              >
                <InIcon />
              </IconButtonWrapper>

              <IconButtonWrapper
                aria-label="x"
                component={Link}
                target="_blank"
                href="https://x.com/prodculator"
              >
                <XIcon />
              </IconButtonWrapper>
            </SocialStack>
          </Grid>
        </Grid>

        <Grid
          container
          mt={{ md: 8, sm: 2, xs: 1 }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item xs={12}>
            <CenterResponsiveBox>
              <Typography
                variant="h5"
                fontWeight={400}
                sx={{
                  color: '#11111180',
                }}
              >
                © 2024 Prodculator. All rights reserved
              </Typography>
            </CenterResponsiveBox>
          </Grid>
        </Grid>
      </Box>
    </SectionWrapper>
  );
};

export default Footer;
