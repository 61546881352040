// material-ui
import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// assets

import BlueBg from 'assets/images/svg/blue-round-big.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/svg/ch-right.svg';
import YellowBg from 'assets/images/svg/yellow-round.svg';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from 'ui-component/button/SecondaryButton';

// ==============================|| LANDING - HeroSection PAGE ||============================== //

const HeroSectionWrapper = styled(Box)(({ theme }) => ({
  padding: '0',
  background: theme.palette.background.black,
  position: 'relative',
  zIndex: 1,
  minHeight: '90vh',
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  ':before': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: '-200px',
    left: '-200px',
    width: '600px',
    height: '600px',
    background: `url(${YellowBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
  },

  ':after': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: '50%',
    right: '0',
    transform: 'translate(50%, -50%)',
    width: '1800px',
    height: '1800px',
    background: `url(${BlueBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },

  [theme.breakpoints.down('md')]: {
    padding: '50px 30px',
  },
}));

const SectionTagline = styled(Typography)(({ theme }) => ({
  background: '#FFCF421A',
  border: '1px solid #FFFFFF1A',
  color: theme.palette.secondary.main,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  width: 'fit-content',
  padding: '4px 12px',
  borderRadius: '24px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    padding: '2px 8px',
  },
}));

const SectionTitleMain = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: 'Baskervville',
  fontWeight: 'bold',
  fontSize: '71px',
  lineHeight: '80px',
  textAlign: 'center',
  maxWidth: 1000,

  [theme.breakpoints.down('md')]: {
    fontSize: '48px',
    lineHeight: '52px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '36px',
    lineHeight: '44px',
  },
}));

const SectionTitleHeighLightedTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'Baskervville',
  fontWeight: 'bold',
  fontSize: '71px',
  lineHeight: '80px',

  [theme.breakpoints.down('md')]: {
    fontSize: '48px',
    lineHeight: '52px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '36px',
    lineHeight: '44px',
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '23px',
  lineHeight: '30px',
  maxWidth: 800,

  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '600',
  },
}));

const HeroSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <HeroSectionWrapper>
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <Grid
            container
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid item xs={12}>
              <Stack gap={4} alignItems={'center'}>
                <SectionTitleMain>
                  We Are A Dynamic Platform Designed To{' '}
                  <SectionTitleHeighLightedTxt component={'span'}>
                    Empower
                  </SectionTitleHeighLightedTxt>{' '}
                </SectionTitleMain>

                <SubTitle>
                  The creative community by connecting creative professionals
                  with global funders and international Grants
                </SubTitle>

                <SectionTagline>
                  More countries & funding opportunities coming soon.
                </SectionTagline>

                <SecondaryButton
                  onClick={() => navigate('/tax-calculator')}
                  title={'Tax Rebate & Incentive Calculator'}
                  endIcon={<ArrowIcon />}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeroSectionWrapper>
  );
};

export default HeroSection;
