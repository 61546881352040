import React from "react";
import { Box, Stack, styled, Typography } from "@mui/material";
import Slider from "react-slick";

import Partner1 from "assets/images/partner/1.png";
import Partner2 from "assets/images/partner/2.png";

// Styled components
const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: "50px 0",
  background: theme.palette.secondary.main,
}));

const PartnerLogoBox = styled(Box)(({ theme }) => ({
  height: "30px",
  padding: "0 15px",
  objectFit: "contain",
}));

const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const OurPartners = () => {
  return (
    <SectionWrapper>
      <Stack alignItems={"center"} justifyContent={"center"} mb={5}>
        <Typography variant="h2" fontWeight={600}>
          Our Partners
        </Typography>
      </Stack>

      <Slider {...settings}>
        <PartnerLogoBox component={"img"} src={Partner1} alt="Partner 1" />
        <PartnerLogoBox component={"img"} src={Partner2} alt="Partner 2" />
        <PartnerLogoBox component={"img"} src={Partner1} alt="Partner 1" />
        <PartnerLogoBox component={"img"} src={Partner2} alt="Partner 2" />
        <PartnerLogoBox component={"img"} src={Partner1} alt="Partner 1" />
        <PartnerLogoBox component={"img"} src={Partner2} alt="Partner 2" />
        <PartnerLogoBox component={"img"} src={Partner1} alt="Partner 1" />
        <PartnerLogoBox component={"img"} src={Partner2} alt="Partner 2" />
        <PartnerLogoBox component={"img"} src={Partner1} alt="Partner 1" />
        <PartnerLogoBox component={"img"} src={Partner2} alt="Partner 2" />
      </Slider>
    </SectionWrapper>
  );
};

export default OurPartners;
