import React, { useState } from "react";
import { Box, Button, Typography, Modal, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { ReactComponent as CookieSvg } from "assets/images/svg/cookie.svg";

const CookieModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const CookieBox = styled(Box)(({ theme }) => ({
  maxWidth: "580px",
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "20px",
  textAlign: "center",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const CookieButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: "#000",
  fontWeight: "bold",
  minWidth: 140,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const PrivacyButton = styled(Typography)(({ theme }) => ({
  color: "#000",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "400",
  textDecoration: "underline",
  textTransform: "none",
}));

export default function CookieConsent({ isOpen, onConsent }) {
  return (
    <CookieModal open={isOpen}>
      <CookieBox>
        <CookieSvg />

        <Stack gap={2} my={5}>
          <Typography variant="h1" fontWeight="600" color="black">
            Our website use Cookies
          </Typography>
          <Typography variant="h5" sx={{ color: '#484848' }}>
            We use cookies and similar technologies to help personalise content,
            tailor and measure ads, and provide a better experience. By clicking
            OK or turning an option on in Cookie Preferences, you agree to this,
            as outlined in our Cookie Policy.
          </Typography>
        </Stack>

        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'baseline'}
          gap={2}
        >
          <PrivacyButton>Privacy Policy</PrivacyButton>
          <CookieButton variant="contained" onClick={onConsent}>
            OK
          </CookieButton>
        </Stack>
      </CookieBox>
    </CookieModal>
  );
}
